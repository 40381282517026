.box-shadow {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.bubble-transfer {
  background-color: #00dca3;
}

.bubble-cancel {
  background-color: #ef233c;
}

.bubble-creation {
  background-color: #b7c6c4;
}

.transaction-bubble {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: inline-block;
}

.selected-transaction {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-bottom: 13px solid #fff;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-top: 13px solid #fff;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
